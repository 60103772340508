import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { confirm: String }

    delete() {
        let confirmed = confirm(this.confirmValue);

        if (!confirmed) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}