import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox", "submit", "email", "timezone"]

    connect() {
        this.updateSubmitState();
        this.setTimezone();
    }

    updateSubmitState() {
        const isAnyCheckboxChecked = this.checkboxTargets.some(checkbox => checkbox.checked);
        const isEmailValid = this.emailInputValue();
        this.submitTarget.disabled = !(isAnyCheckboxChecked && isEmailValid);
    }

    toggle(event) {
        const checkbox = event.currentTarget.querySelector('[type="checkbox"]');
        if (event.target.type !== 'checkbox') {
            checkbox.checked = !checkbox.checked;
        }
        this.updateSubmitState();
    }

    emailInputValue() {
        return this.validateEmail(this.emailTarget.value);
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    setTimezone() {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.timezoneTarget.value = timezone;
    }
}
