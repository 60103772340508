import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static targets = ["container"];

    hide() {
        Promise.all([
            leave(this.containerTarget)
        ]).then(() => {
            this.containerTarget.classList.add("hidden");
        });
    }

    show() {
        this.containerTarget.classList.remove("hidden");
        enter(this.containerTarget);
    }
}