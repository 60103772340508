import { Controller } from "@hotwired/stimulus"
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';

export default class extends Controller {
    static targets = ['editor', 'bold', 'italic', 'strike', 'content'];

    connect() {
        const self = this;
        this.editor = new Editor({
            element: this.editorTarget,
            extensions: [StarterKit, Link, Image],
            content: this.contentTarget.value,
            onUpdate: this.onUpdate.bind(this),
            onSelectionUpdate({ editor }) {
                self.updateBoldButton();
            }
        })
        const tiptap = this.element.querySelector(".tiptap");
        tiptap.classList.add(
            "bg-white",
            "rounded-md",
            "p-1.5",
            "text-gray-900",
            "ring-1",
            "ring-gray-300",
            "focus:ring-1",
            "focus:ring-sierra-aurora-600",
            "sm:text-sm"
        );
    }

    onUpdate({ editor }) {
        this.contentTarget.value = editor.getHTML();
    }

    toggleBold() {
        this.stopEvent();
        this.editor.chain().focus().toggleBold().run();
        this.updateBoldButton();
    }

    updateBoldButton() {
        if (this.editor.isActive('bold')) {
            this.selectBoldButton();
        } else {
            this.unselectBoldButton();
        }
    }

    selectBoldButton() {
        this.boldTarget.classList.add("ring-0", "bg-sierra-aurora", "text-white");
    }

    unselectBoldButton() {
        this.boldTarget.classList.remove("ring-0", "bg-sierra-aurora", "text-white");
    }

    disconnect() {
        this.editor.destroy();
    }

    stopEvent() {
        event.preventDefault();
        event.stopPropagation();
    }
}