import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = ["input", "response", "button", "spinner"]

    generate() {
        this.showSpinner()
        this.responseTarget.innerText = ''
        Rails.ajax({
            url: "/admin/marketing/generate_blog",
            type: "POST",
            data: `content=${this.inputTarget.value}`,
            success: (data) => {
                this.hideSpinner()
                this.responseTarget.innerText = data.content;
            },
            error: (err) => {
                hideSpinner()
                console.error(err);
            }
        })
    }

    showSpinner() {
        this.buttonTarget.classList.add("hidden");
        this.spinnerTarget.classList.remove("hidden");
    }

    hideSpinner() {
        this.buttonTarget.classList.remove("hidden");
        this.spinnerTarget.classList.add("hidden");
    }
}